import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

function getFBP() {
  const match = document.cookie.match(/_fbp=([^;]+)/);
  return match ? match[1] : null;
}

// Static mapping of routes to page titles
const pageTitles: Record<string, string> = {
  "/": "Home",
  "/chats": "Chats",
  "/saved": "Saved",
  "/price": "Price",
  "/setting": "Setting",
  "/successful-payment": "Successful Payment",
  // Add more static mappings as needed
};

export const sendTrackingData = async (
  event: string,
  fbclid: string | null,
  pageTitle: string,
  custom_data: any = {}
) => {
  const fbp = getFBP();
  custom_data.fbp = fbp;
  try {
    await axios.post(
      `${process.env.REACT_APP_REST_API_URI}/api/v1/meta-track`,
      {
        fbclid,
        url: window.location.href,
        page_title: pageTitle, // Send static page title
        event_name: event, // Specify the event name
        custom_data,
      }
    );
  } catch (error) {
    console.error("Tracking Error:", error);
  }
};

export const callTrackingWithFbclid = (
  event: string,
  pageTitle: string,
  custom_data: any = {}
) => {
  console.log("hello", event, pageTitle, custom_data);
  const urlParams = new URLSearchParams(window.location.search);
  const fbclidFromUrl = urlParams.get("fbclid");
  const fbclidFromStorage = localStorage.getItem("fbclid");

  const fbclid = fbclidFromUrl || fbclidFromStorage; // Use fbclid from URL or localStorage

  custom_data.email = localStorage.getItem("email") || "";

  sendTrackingData(event, fbclid, pageTitle, custom_data);
};

const useFbclid = () => {
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const fbclid = urlParams.get("fbclid");
    const path = location.pathname; // Get the current path from react-router
    const pageTitle = pageTitles[path] || "Default Page"; // Map to static title or use a default

    if (fbclid) {
      localStorage.setItem("fbclid", fbclid);
      sendTrackingData("PageView", fbclid, pageTitle, {
        email: localStorage.getItem("email") || "",
      }); // Send immediately for page views
    } else {
      sendTrackingData("PageView", null, pageTitle, {
        email: localStorage.getItem("email") || "",
      }); // Track regular page views without fbclid
    }

    if (path === "/successful-payment") {
      sendTrackingData("Purchase", fbclid, pageTitle, {
        email: localStorage.getItem("email") || "",
      });
    }
  }, [location.pathname]); // Re-run effect every time the route changes
};

export default useFbclid;
