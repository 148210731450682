import * as React from "react";
import {
  createSubscription,
  getChatPointBooster,
  getCurrentSubscription,
  getPricingAvailable,
  purchaseChatPointBooster,
} from "../../api/rest-apis";
import ChatpointBooster from "../../components/chatpoint-booster";
import Header from "../../components/header";
import Popup from "../../components/popup";
import SideMenu from "../../components/sideMenu";
import Spin from "../../helpers/spin";
import withRouter from "../../helpers/withRouter";
import ConfirmFreeTrail from "./components/setting/confirm-free-trail";
import {
  callTrackingWithFbclid,
  sendTrackingData,
} from "../../hooks/useFbclid";

const boosterPackages = [
  {
    id: "booster25",
    title: "25 ChatPoint Boosters",
    price_id: process.env.REACT_APP_CHATPOINT_BOOSTER_25_PRICE_ID,
    btn: "Boost for $1.99",
    price: 1.99,
    benefits: [
      {
        check: true,
        content: "Finish your conversation.",
      },
    ],
    off: 0,
    unlimited: true,
    available: {
      sold: 0,
      total: 0,
    },
  },
  {
    id: "booster75",
    title: "75 ChatPoint Boosters",
    price_id: process.env.REACT_APP_CHATPOINT_BOOSTER_75_PRICE_ID,
    btn: "Boost for $4.99",
    price: 4.99,
    benefits: [
      {
        check: true,
        content:
          "Great for the Subscriber tier where each ChatPoint goes a long way.",
      },
    ],
    off: 15,
    unlimited: true,
    available: {
      sold: 0,
      total: 0,
    },
  },

  {
    id: "booster165",
    title: "165 ChatPoint Boosters",
    price_id: process.env.REACT_APP_CHATPOINT_BOOSTER_175_PRICE_ID,
    btn: "Boost for $9.99",
    price: 9.99,
    benefits: [
      {
        check: true,
        content: " Good for the Patron tier.",
      },
    ],
    off: 20,
    unlimited: true,
    available: {
      sold: 5,
      total: 0,
    },
  },

  {
    id: "booster530",
    title: "530 ChatPoint Boosters",
    price_id: process.env.REACT_APP_CHATPOINT_BOOSTER_550_PRICE_ID,
    btn: "Boost for $29.99",
    price: 29.99,
    benefits: [
      {
        check: true,
        content:
          "Good for the Premium tier where voice uses a lot of ChatPoints.",
      },
      {
        check: true,
        content:
          " Good for longer conversations in the Patron tier where, the longer your conversation, the more ChatPoints you use with each message.",
      },
    ],
    off: 25,
    unlimited: true,
    available: {
      sold: 5,
      total: 10,
    },
  },

  {
    id: "booster6000",
    title: "6000 ChatPoint Boosters",
    price_id: process.env.REACT_APP_CHATPOINT_BOOSTER_1200_PRICE_ID,
    btn: "Boost for $299.99",
    price: 299.99,
    benefits: [
      {
        check: true,
        content:
          "Best value because your ChatPoint Boosters will carry over each month.",
      },
    ],
    off: 35,
    unlimited: true,
    available: {
      sold: 1,
      total: 1,
    },
  },
];

interface PricingProps {
  openChatPointBooster: boolean;
}

interface PricingState {}

class Pricing extends React.Component<PricingProps, PricingState> {
  state = {
    currentSubscription: "",
    canceled: false,
    loading: false,
    indexSubscribing: -1,
    boosterModal: false,
    boosterLoading: false,
    boosterAddingIndex: -1,
    freeTrailModal: false,
    boosterPackages,
  };

  chatPointBooster!: React.RefObject<HTMLDivElement>;

  constructor(props: PricingProps) {
    super(props);
    this.chatPointBooster = React.createRef();
  }
  prices = [
    {
      price: 0,
      plan: "Basic",
      planName: "Subscriber",
      desc: "Author Newsletter",
      benefits: [
        { check: true, content: "1 ChatPoints per month" },
        { check: true, content: "Author updates" },
        { check: true, content: "Sexy chats" },
        { check: true, content: "Surprising conversations" },

        { check: false, content: "Exclusive short stories" },
        { check: false, content: "Read chapters as they're written" },
        { check: false, content: "Supporting the author" },
        { check: false, content: "Additional sexy characters" },
        { check: false, content: "Read the characters' full novels" },
        { check: false, content: "Chat with the characters as you read" },
        { check: false, content: "Characters have voices" },
      ],
    },
    // {
    //   price: 4.99,
    //   plan: "Starter",
    //   desc: "Just a Taste",
    //   benefits: [
    //     { check: true, content: "75 ChatPoints per month" },
    //     { check: true, content: "Basic conversations" },
    //     { check: false, content: "Genius-level chatbots" },
    //     { check: false, content: "Personality Mode" },
    //     { check: false, content: "Steamy chats" },
    //     { check: false, content: "Get advice" },
    //     { check: false, content: "Charming & Engaging conversations" },
    //     { check: false, content: "Chatbots have voices" },
    //   ],
    // },
    {
      price: 4.99,
      plan: "Plus",
      planName: "Patron",
      desc: "Full Access ",
      benefits: [
        { check: true, content: "75 ChatPoints per month" },
        { check: true, content: "Author updates" },
        { check: true, content: "Sexy chats" },
        { check: true, content: "Surprising conversations" },

        { check: true, content: "Exclusive short stories" },
        { check: true, content: "Read chapters as they're written" },
        { check: true, content: "Supporting the author" },
        { check: false, content: "Additional sexy characters" },
        { check: false, content: "Read the characters' full novels" },
        { check: false, content: "Chat with the characters as you read" },
        { check: false, content: "Characters have voices" },
      ],
    },
    {
      price: 14.99,
      plan: "Premium",
      planName: "Premium",
      desc: "All Characters, Stories, & Voices",
      benefits: [
        { check: true, content: "225 ChatPoints per month" },
        { check: true, content: "Author updates" },
        { check: true, content: "Sexy chats" },
        { check: true, content: "Surprising conversations" },

        { check: true, content: "Exclusive short stories" },
        { check: true, content: "Read chapters as they're written" },
        { check: true, content: "Supporting the author" },
        { check: true, content: "Additional sexy characters" },
        { check: true, content: "Read the characters' full novels" },
        { check: true, content: "Chat with the characters as you read" },
        { check: true, content: "Characters have voices" },
      ],
    },
  ];
  async componentDidMount() {
    try {
      // Fetch the chat point booster settings (boolean values)
      const settings = (await getChatPointBooster()) as {
        CHATPOINT_BOOSTER_25: boolean;
        CHATPOINT_BOOSTER_75: boolean;
        CHATPOINT_BOOSTER_175: boolean;
        CHATPOINT_BOOSTER_550: boolean;
        CHATPOINT_BOOSTER_1200: boolean;
      };

      // Mapping package id to settings key
      const mapping: Record<string, keyof typeof settings> = {
        booster25: "CHATPOINT_BOOSTER_25",
        booster75: "CHATPOINT_BOOSTER_75",
        booster165: "CHATPOINT_BOOSTER_175",
        booster530: "CHATPOINT_BOOSTER_550",
        booster6000: "CHATPOINT_BOOSTER_1200",
      };

      // Filter out the packages where the corresponding setting is false
      const filteredPackages = boosterPackages.filter(
        (pkg) => settings[mapping[pkg.id]]
      );

      this.setState({ boosterPackages: filteredPackages, loading: false });
    } catch (error) {
      console.error("Error fetching booster settings", error);
      this.setState({ loading: false });
    }
    this.currentSubscription();

    if (this.props.openChatPointBooster) {
      setTimeout(() => {
        if (this.chatPointBooster.current) {
          this.chatPointBooster.current.scrollIntoView();
        }
      }, 100);
    }

    await this.updatePricing();
  }

  async updatePricing() {
    try {
      const response: any = await getPricingAvailable();
      console.log(response);

      const updatedBoosterPackages = this.state.boosterPackages.map(
        (boosterPackage) => {
          const matchingBooster = response.find(
            (booster: any) => booster.id === boosterPackage.id
          );

          // If there's a matching booster in the response, update the available field
          if (matchingBooster) {
            return {
              ...boosterPackage,
              available: matchingBooster.available,
              unlimited: matchingBooster.unlimited,
            };
          }

          return boosterPackage;
        }
      );

      this.setState({ boosterPackages: updatedBoosterPackages });
    } catch (error) {
      console.error(error);
    }
  }

  async subscribe(plan: string, freeTrail: boolean = false) {
    if (
      this.state.currentSubscription === plan ||
      this.state.currentSubscription === ""
    )
      return;
    if (plan === "Basic") return;
    console.log(plan, freeTrail);
    if (freeTrail === false) {
      callTrackingWithFbclid("Initiate Checkout", "Price", { plan });
    }

    if (
      plan === "Plus" &&
      !this.state.freeTrailModal &&
      this.state.currentSubscription === "Basic"
    ) {
      this.setState({ freeTrailModal: true });
      return;
    }
    if (plan === "Plus" && freeTrail === true)
      callTrackingWithFbclid("Start Trial", "Price");

    this.setState({
      loading: true,
      indexSubscribing: ["Basic", "Plus", "Premium"].indexOf(plan),
    });
    try {
      const response: any = await createSubscription(
        plan.toLowerCase() === "plus"
          ? (process.env.REACT_APP_PLUS_PRICE_ID as string)
          : (process.env.REACT_APP_PREMIUM_PRICE_ID as string),
        freeTrail
      );
      window.location.href = response.sessionUrl;
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false, indexSubscribing: -1 });
    }
  }

  async currentSubscription() {
    try {
      const response: any = await getCurrentSubscription();
      this.setState({
        currentSubscription: response.subscription.planName,
        canceled: response.subscription.canceled,
      });

      setTimeout(() => console.log(this.state), 300);
    } catch (error) {
      this.setState({
        currentSubscription: "Basic",
        canceled: false,
      });
      console.error(error);
      // Handle error
    }
  }

  toggleBoosterModal() {
    this.setState({ boosterModal: !this.state.boosterModal });
  }

  async purchasChatPointBooster(price_id: string | undefined, index: number) {
    try {
      this.setState({ boosterLoading: true, boosterAddingIndex: index });
      const response: any = await purchaseChatPointBooster(price_id);
      window.location.href = response.sessionUrl;
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ boosterLoadding: false, boosterAddingIndex: -1 });
    }
  }

  render() {
    return (
      <>
        {this.state.boosterModal && (
          <ChatpointBooster
            closeModal={() => this.toggleBoosterModal()}
          ></ChatpointBooster>
        )}

        {this.state.freeTrailModal && (
          <ConfirmFreeTrail
            reverseFunction={(bool: boolean) => {
              this.subscribe("Plus", bool);
            }}
            open={this.state.freeTrailModal}
            setOpen={(bool: boolean) => this.setState({ freeTrailModal: bool })}
          ></ConfirmFreeTrail>
        )}

        <div className="home-container">
          <SideMenu></SideMenu>
          <div className="main-view ">
            <Header></Header>
            <section className="bg-white">
              <div className="py-8 px-4 mx-auto max-w-[1580px] lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                  <h2 className="mb-4 lg:text-4xl tracking-tight font-extrabold text-gray-900  sm:text-lg">
                    Explore Subscription Options
                  </h2>
                  {/* <p
                    className="mb-5 font-light sm:text-xl cursor-pointer"
                    onClick={() => this.toggleBoosterModal()}
                    style={{ color: "#0070E0" }}
                  >
                    Looking for ChatPoint booster ?
                  </p> */}
                </div>

                <div className="flex flex-wrap justify-start gap-4">
                  {this.prices.map((value, index) => (
                    <div
                      className="flex flex-col p-6 max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow "
                      style={{
                        width: "22rem",
                        margin: "auto",
                        border:
                          this.state.currentSubscription === "Basic" &&
                          index === 0
                            ? // ? "5px solid var(--primary-color)"
                              // : this.state.currentSubscription === "Starter" &&
                              //   index === 1
                              "5px solid var(--primary-color)"
                            : this.state.currentSubscription === "Plus" &&
                              index === 1
                            ? "5px solid var(--primary-color)"
                            : this.state.currentSubscription === "Premium" &&
                              index === 2
                            ? "5px solid var(--primary-color)"
                            : "",
                        borderRadius: "30px",
                      }}
                    >
                      <h3 className="mb-4 text-2xl font-semibold">
                        {value.planName}
                      </h3>
                      <p
                        className="font-light text-gray-500 sm:text-lg "
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {value.desc}
                        {value.plan === "Plus" && (
                          <>
                            <br />
                            (Free Trial - 22 ChatPoints)
                          </>
                        )}
                      </p>
                      {index === 2 && <br />}
                      <div className="flex justify-center items-baseline my-8">
                        <span className="mr-2 text-5xl font-extrabold">
                          ${value.price}
                        </span>
                        <span className="text-gray-500 ">/month</span>
                      </div>
                      <ul role="list" className="mb-8 space-y-4 text-left">
                        {value.benefits.map((benefit) => (
                          <li className="flex items-center space-x-3">
                            {benefit.check ? (
                              <img src="/assets/images/check.svg" alt="" />
                            ) : (
                              <img src="/assets/images/x.svg" alt="" />
                            )}

                            <span>{benefit.content}</span>
                          </li>
                        ))}
                      </ul>
                      <div className="bookish-input-group ">
                        <button
                          className="bookish-primary-btn"
                          style={{
                            width: "100%",
                            cursor:
                              index === 0
                                ? "default"
                                : // : this.state.currentSubscription ===
                                //     "Starter" && index === 1
                                // ? "default"
                                this.state.currentSubscription === "Plus" &&
                                  index === 1
                                ? "default"
                                : this.state.currentSubscription ===
                                    "Premium" && index === 2
                                ? "default"
                                : "cursor",
                          }}
                          onClick={() => this.subscribe(value.plan)}
                        >
                          {this.state.loading &&
                            this.state.indexSubscribing === index && (
                              <div className={"flex justify-center"}>
                                <Spin></Spin>
                                <span style={{ color: "#fff" }}>
                                  Processing...
                                </span>
                              </div>
                            )}

                          {this.state.indexSubscribing !== index && (
                            <span style={{ color: "#fff" }}>
                              {" "}
                              {index === 0
                                ? "Free"
                                : // : this.state.currentSubscription ===
                                //     "Starter" && index === 1
                                // ? "Subscribed"
                                this.state.currentSubscription === "Plus" &&
                                  index === 1
                                ? "Subscribed"
                                : this.state.currentSubscription ===
                                    "Premium" && index === 2
                                ? "Subscribed"
                                : "Subscribe Now"}
                            </span>
                          )}
                        </button>
                        {/* <p
                          className="mb-5 font-bold sm:text-md mt-1 cursor-pointer"
                          onClick={() => this.toggleBoosterModal()}
                          style={{ color: "var(--primary-color)" }}
                        >
                          Extend Your Conversations
                        </p> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>

            {/* Chatpoint Booster package */}

            <section className="bg-white">
              <div className="py-8 px-4 mx-auto max-w-[1580px] lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                  <h2
                    ref={this.chatPointBooster}
                    className="mb-4 lg:text-4xl tracking-tight font-extrabold text-gray-900  sm:text-lg"
                  >
                    Extend Your Conversations
                  </h2>
                </div>

                <div className="flex flex-wrap justify-start gap-4">
                  {this.state.boosterPackages.map((value, index) => (
                    <div
                      className="flex flex-col p-6 max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow "
                      style={{
                        minWidth: "15rem",
                        maxWidth: "22rem",
                        margin: "0 auto",
                        height: "22rem !important",
                        border:
                          index === 2
                            ? "5px solid var(--primary-color)"
                            : "none",

                        borderRadius: "30px",
                      }}
                    >
                      {index === 2 && <br />}
                      <h3 className="mb-4 text-2xl font-semibold">
                        {value.title}
                      </h3>
                      <div className="flex justify-center items-baseline mt-8">
                        <p className="mr-2 text-5xl font-extrabold">
                          ${value.price}
                        </p>
                      </div>
                      <div className="flex items-center justify-center">
                        {value.off > 0 ? (
                          <div className="bg-red-500 text-white font-bold my-4 py-1 px-4 rounded-full">
                            ${value.off}% off
                          </div>
                        ) : (
                          <div className=" text-white font-bold my-4 py-1 px-4 rounded-full"></div>
                        )}
                      </div>

                      <p className="font-bold mb-3">
                        {!value.unlimited && value.available.total > 0 ? (
                          <>
                            {value.available.total - value.available.sold} /
                            {value.available.total} remaining this month
                          </>
                        ) : (
                          <></>
                        )}
                      </p>

                      <ul role="list" className="mb-8 space-y-4 text-left">
                        {value.benefits.map((benefit) => (
                          <li className="flex items-center space-x-3">
                            {benefit.check ? (
                              <img src="/assets/images/check.svg" alt="" />
                            ) : (
                              <img src="/assets/images/x.svg" alt="" />
                            )}

                            <span>{benefit.content}</span>
                          </li>
                        ))}
                      </ul>
                      <div
                        className="bookish-input-group "
                        style={{ marginTop: "auto" }}
                      >
                        <button
                          className="bookish-primary-btn"
                          onClick={() =>
                            this.purchasChatPointBooster(value.price_id, index)
                          }
                          disabled={
                            value.available.total - value.available.sold ===
                              0 && !value.unlimited
                          }
                          style={{
                            width: "16rem",
                            marginTop: "auto",
                            cursor:
                              value.available.total - value.available.sold ===
                                0 && !value.unlimited
                                ? "default"
                                : "pointer",
                            opacity:
                              value.available.total - value.available.sold ===
                                0 && !value.unlimited
                                ? 0.85
                                : 1,
                          }}
                        >
                          {this.state.boosterLoading &&
                          this.state.boosterAddingIndex === index ? (
                            <div className={"flex justify-center"}>
                              <Spin></Spin>
                              <span style={{ color: "#fff" }}>
                                Processing...
                              </span>
                            </div>
                          ) : (
                            <span style={{ color: "#fff" }}>
                              {" "}
                              Boost for ${value.price}
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Pricing);
