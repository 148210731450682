import * as React from "react";
import {
  getChatPointBooster,
  updateChatPointBooster,
} from "../../../api/rest-apis";
import Spin from "../../../helpers/spin";
import { toastHelper } from "../../../helpers/toast";

interface ChatPointBoosterProps {}

interface ChatPointBoosterState {
  CHATPOINT_BOOSTER_25: boolean;
  CHATPOINT_BOOSTER_75: boolean;
  CHATPOINT_BOOSTER_175: boolean;
  CHATPOINT_BOOSTER_550: boolean;
  CHATPOINT_BOOSTER_1200: boolean;
  loading: boolean;
}

class ChatPointBooster extends React.Component<
  ChatPointBoosterProps,
  ChatPointBoosterState
> {
  state: ChatPointBoosterState = {
    CHATPOINT_BOOSTER_25: false,
    CHATPOINT_BOOSTER_75: false,
    CHATPOINT_BOOSTER_175: false,
    CHATPOINT_BOOSTER_550: false,
    CHATPOINT_BOOSTER_1200: false,
    loading: false,
  };

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const data: any = await getChatPointBooster();
      this.setState({
        CHATPOINT_BOOSTER_25: data.CHATPOINT_BOOSTER_25,
        CHATPOINT_BOOSTER_75: data.CHATPOINT_BOOSTER_75,
        CHATPOINT_BOOSTER_175: data.CHATPOINT_BOOSTER_175,
        CHATPOINT_BOOSTER_550: data.CHATPOINT_BOOSTER_550,
        CHATPOINT_BOOSTER_1200: data.CHATPOINT_BOOSTER_1200,
        loading: false,
      });
    } catch (error) {
      toastHelper.error("Couldn't fetch chat point booster settings");
      console.error(error);
      this.setState({ loading: false });
    }
  }

  handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked } as Pick<
      ChatPointBoosterState,
      keyof ChatPointBoosterState
    >);
  };

  async handleSubmit() {
    this.setState({ loading: true });
    const {
      CHATPOINT_BOOSTER_25,
      CHATPOINT_BOOSTER_75,
      CHATPOINT_BOOSTER_175,
      CHATPOINT_BOOSTER_550,
      CHATPOINT_BOOSTER_1200,
    } = this.state;

    try {
      await updateChatPointBooster({
        CHATPOINT_BOOSTER_25,
        CHATPOINT_BOOSTER_75,
        CHATPOINT_BOOSTER_175,
        CHATPOINT_BOOSTER_550,
        CHATPOINT_BOOSTER_1200,
      });
      toastHelper.success("Updated successfully");
      this.setState({ loading: false });
    } catch (error) {
      toastHelper.error("Couldn't update chat point booster settings");
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div className="mx-auto mt-12">
        <div className="title">Chat Point Booster Visibility Settings</div>
        <div className="flex mt-6 max-w-[23rem]">
          <input
            type="checkbox"
            id="CHATPOINT_BOOSTER_25"
            name="CHATPOINT_BOOSTER_25"
            checked={this.state.CHATPOINT_BOOSTER_25}
            onChange={this.handleCheckboxChange}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />

          <label
            className="ml-4 block text-gray-700 text-sm font-bold mb-2"
            htmlFor="CHATPOINT_BOOSTER_25"
          >
            ChatPoint Booster 25
          </label>
        </div>
        <div className="flex mt-6 max-w-[23rem]">
          <input
            type="checkbox"
            id="CHATPOINT_BOOSTER_75"
            name="CHATPOINT_BOOSTER_75"
            checked={this.state.CHATPOINT_BOOSTER_75}
            onChange={this.handleCheckboxChange}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />

          <label
            className="ml-4 block text-gray-700 text-sm font-bold mb-2"
            htmlFor="CHATPOINT_BOOSTER_75"
          >
            ChatPoint Booster 75
          </label>
        </div>
        <div className="flex mt-6 max-w-[23rem]">
          <input
            type="checkbox"
            id="CHATPOINT_BOOSTER_175"
            name="CHATPOINT_BOOSTER_175"
            checked={this.state.CHATPOINT_BOOSTER_175}
            onChange={this.handleCheckboxChange}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label
            className="ml-4 block text-gray-700 text-sm font-bold mb-2"
            htmlFor="CHATPOINT_BOOSTER_175"
          >
            ChatPoint Booster 175
          </label>
        </div>
        <div className="flex mt-6 max-w-[23rem]">
          <input
            type="checkbox"
            id="CHATPOINT_BOOSTER_550"
            name="CHATPOINT_BOOSTER_550"
            checked={this.state.CHATPOINT_BOOSTER_550}
            onChange={this.handleCheckboxChange}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label
            className="ml-4 block text-gray-700 text-sm font-bold mb-2"
            htmlFor="CHATPOINT_BOOSTER_550"
          >
            ChatPoint Booster 550
          </label>
        </div>
        <div className="flex mt-6 max-w-[23rem]">
          <input
            type="checkbox"
            id="CHATPOINT_BOOSTER_1200"
            name="CHATPOINT_BOOSTER_1200"
            checked={this.state.CHATPOINT_BOOSTER_1200}
            onChange={this.handleCheckboxChange}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label
            className="ml-4 block text-gray-700 text-sm font-bold mb-2"
            htmlFor="CHATPOINT_BOOSTER_1200"
          >
            ChatPoint Booster 1200
          </label>
        </div>

        <div className="bookish-input-group mt-4 create-character-btn">
          <button
            className="bookish-primary-btn"
            onClick={() => this.handleSubmit()}
            disabled={this.state.loading}
          >
            {this.state.loading && (
              <div className="flex justify-center items-center">
                <Spin />
                <span style={{ color: "#fff" }}>Processing...</span>
              </div>
            )}
            {!this.state.loading && (
              <span style={{ color: "#fff" }}>Update Chat Point Booster</span>
            )}
          </button>
        </div>
      </div>
    );
  }
}

export default ChatPointBooster;
