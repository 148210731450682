import axios from "../helpers/axios";
import {
  AdminLoginRequest,
  Character,
  ChatRequest,
  Save,
  Support,
  UserSignupForm,
} from "../interfaces/rest-api";

// Admin Login
export const adminLoginLogin = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/admin-login`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateCharacterOrder = async (
  updatedCharacters: { _id: string; position: number }[]
) => {
  try {
    const response = await axios.put("/api/v1/characters/reorder", {
      updatedCharacters,
    });

    return response.data;
  } catch (error) {
    console.error("Error updating character order via Axios:", error);
    throw error;
  }
};

// Admin Login (Login)
export const adminLogin = async (form: AdminLoginRequest) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`/api/v1/admin-login/login`, form);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Account Signup Form
export const accountSignupForm = async (formData: UserSignupForm) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `/api/v1/account/signup-form`,
        formData
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Popular Characters
export const getPopularCharacters = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/popular-characters`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Tags
export const getTags = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/tags`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postTags = async (payload: { tags: string }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`/api/v1/tags`, payload);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Character
export const getCharacters = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/character`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCharacterById = async (characterId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/character/${characterId}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postCharacter = async (characterData: Character) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`/api/v1/character`, characterData);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const putCharacter = async (characterId: string, updatedData: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.put(`/api/v1/character`, {
        _id: characterId,
        ...updatedData,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Delete Character
export const deleteCharacter = async (
  characterId: string,
  assistantId: string | undefined = undefined
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.delete(
        `/api/v1/character/${characterId}${
          assistantId ? "?assistantId=" + assistantId : ""
        }`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Upload Character Profile
export const uploadCharacterProfile = async (profileData: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `/api/v1/upload/character-profile`,
        profileData
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Save
export const getSave = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/save`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postSave = async (saveData: Save) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`/api/v1/save`, saveData);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Delete Save
export const deleteSave = async (saveId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.delete(`/api/v1/save/${saveId}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Admin Analytics
export const getAdminAnalytics = async (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`/api/v1/admin/analytics`, payload);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Admin Analytics for Character
export const getAdminAnalyticsForCharacter = async (
  characterId: string,
  payload: any
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `/api/v1/admin/analytic/${characterId}`,
        payload
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Chat Info for Character
export const getChatInfoForCharacter = async (characterId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/chat/chat-info/${characterId}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Chat Info for Character
export const getUsers = async (
  timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/v1/admin/users${
          timezone.length > 0 ? `?timezone=${timezone}` : ""
        }`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Chat Info for Character
export const getUserTokenUsage = async (user_id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/v1/admin/analytic/user-token-usage/${user_id}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Chat Info for Character
export const getUserInfoById = async (user_id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/admin/user/${user_id}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Grap old conversations
export const getConversationsByConversationId = async (
  conversationId: string
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/v1/chat/conversation/${conversationId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Get Previous Conversation
export const getPreviousConversations = async (timezone: string = "") => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/v1/chat/conversations${
          timezone.length > 0 ? `?timezone=${timezone}` : ""
        }`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Convert character voice
export const textToVoice = async (text: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/audio/text=${text}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
// Delete Character
export const deleteConversation = async (conversationId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.delete(
        `/api/v1/chat/conversation/${conversationId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

const handleStream = async (
  response: any,
  callback: any,
  finishedCallback: any
) => {
  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  let buffer = ""; // Store accumulated chunks

  const read = async () => {
    const { done, value } = await reader.read();
    if (done) {
      console.log("Stream finished.");
      finishedCallback(true);
      return;
    }

    const chunk = decoder.decode(value, { stream: true });

    buffer += chunk; // Add chunk to buffer

    // Splitting by double newline to handle SSE data format
    const events = buffer.split("\n\n");

    // Retain the last incomplete event in the buffer
    buffer = events.pop() || "";

    events.forEach((event) => {
      const dataPrefix = "data: ";

      if (event.trim().startsWith(dataPrefix)) {
        const jsonData = event.substring(dataPrefix.length);
        try {
          // Safeguard: Only attempt JSON parsing if it seems complete
          if (jsonData.trim().endsWith("}")) {
            const data = JSON.parse(jsonData);

            callback(data); // Call the callback with parsed data
          } else {
            console.warn(
              "Incomplete JSON data received, waiting for next chunk."
            );
          }
        } catch (error) {
          console.error("Error parsing JSON from stream:", error);

          // Save the problematic JSON data in case of an error
          localStorage.setItem("lastErrorJSON", jsonData);
          console.log(
            "Partial or malformed data saved to localStorage:",
            jsonData
          );
        }
      }
    });

    // Recursively read the next chunk
    read();
  };

  read().catch((error) => console.error("Error reading the stream:", error));
};

export const postChatForCharacter = async (
  characterId: string,
  bookTalk: boolean,
  chatData: ChatRequest,
  callback: any,
  finishedCallback: any
) => {
  let baseUrl = process.env.REACT_APP_REST_API_URI_v2 as string;

  const url = `${baseUrl}/api/v1/chat/${characterId}?bookTalk=${bookTalk}`;
  const token = window.location.pathname.includes("/admin")
    ? localStorage.getItem("adminToken")
    : localStorage.getItem("token");

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // Include any other headers your request might need
      },
      body: JSON.stringify(chatData),
    });

    // Check if the response is OK
    if (!response.ok) {
      // Attempt to parse the error message from the response body
      const errorBody = await response.json(); // Use .text() or other methods depending on the expected response format
      throw errorBody; //new Error("Network response was not ok");
    }

    // Here you could inspect headers or status before deciding how to process the body
    // For example, checking for a streaming content type
    const contentType = response.headers.get("Content-Type");
    if (contentType === "text/event-stream") {
      // Handle streaming response
      await handleStream(response, callback, finishedCallback);
      return null;
    }

    // Assuming a standard JSON response for simplicity
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There has been a problem with your fetch operation:", error);
    throw error; // Rethrow or handle error as needed
  }
};

// Create Subscription
export const createSubscription = async (
  subscriptionId: string,
  freeTrail: boolean = false
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`/api/v1/subscription/create`, {
        plan: subscriptionId,
        freeTrail,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Acitvate Plus from trail
export const activateTrailSubscription = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `/api/v1/subscription/activate-plus-trail-now`,
        {}
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Purchase chatpoint booster
export const purchaseChatPointBooster = async (
  price_id: string | undefined
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `/api/v1/subscription/purchase-chatpoint-boosters`,
        {
          price_id,
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Get Current Subscription
export const getCurrentSubscription = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `/api/v1/subscription/current-subscription`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Get Current Subscription
export const getSessionUrlForCustomerPortal = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/subscription/customer-portal`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Get Invoice Data
export const getInvoice = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/subscription/invoice`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Cancel Subscription
export const cancelSubscription = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `/api/v1/subscription/cancel-subscription`,
        {}
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Chat Info for Character
export const getAccountInfo = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/account/account-info`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Save or Update Custom Prompt Pair
export const saveOrUpdateCustomPromptPair = async (
  characterId: string,
  assistantId: string,
  prompt: string,
  response: string
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.post(
        "/api/v1/character-prompt/custom-prompt-pairs",
        {
          character_id: characterId,
          assistant_id: assistantId,
          prompt: prompt,
          response: response,
        }
      );
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Delete Custom Prompt Pair
export const deleteCustomPromptPair = async (
  characterId: string,
  assistantId: string,
  prompt: string
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.delete(
        "/api/v1/character-prompt/custom-prompt-pairs",
        {
          params: {
            character_id: characterId,
            assistant_id: assistantId,
            prompt: prompt,
          },
        }
      );
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const searchCustomPromptPair = async (
  characterId: string,
  assistantId: string,
  prompt: string
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.get(
        "/api/v1/character-prompt/custom-prompt-pairs",
        {
          params: {
            character_id: characterId,
            assistant_id: assistantId,
            prompt: prompt,
          },
        }
      );
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
};
// Cancel Subscription
export const postSupport = async (payload: Support) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`/api/v1/support`, payload);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Admin block toggle
export const adminBlockToggle = async (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.put(`/api/v1/admin/user-status`, payload);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const adminGetBoosters = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/admin/booster-quantity`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getPricingAvailable = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/pricing-packages-available`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postBoosterQuantity = async (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `/api/v1/admin/booster-quantity`,
        payload
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Admin get models
export const adminGetModels = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/openai/models`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Delete OpenAI file
export const deleteCharacterOpenAIFile = async (fileId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.delete(
        `/api/v1/openai/delete-file/${fileId}`
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getPricingModels = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/pricing-model`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const postPricingModel = async (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`/api/v1/pricing-model`, payload);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Admin block toggle
export const putPricingModel = async (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.put(`/api/v1/pricing-model`, payload);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Admin get

export const deletePricingModel = async (_id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.delete(`/api/v1/pricing-model/${_id}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Get Current Subscription
export const getSessionToken = async (uuid: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/retrieve-token?uuid=${uuid}`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Update or Insert Free Trial Pricing
export const updateFreeTrailPricing = async (data: {
  basic_plan_credit_amount: number;
  basic_plan_bonus_credit: number;
  plus_plan_free_trial_credit_amount: number;
  bonus_credit_delay_hours: number;
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.put(
        `/api/v1/pricing-model/free-trail`,
        data
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Get Free Trial Pricing
export const getFreeTrailPricing = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/pricing-model/free-trail`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Update or Insert Chat Point Booster Settings
export const updateChatPointBooster = async (data: {
  CHATPOINT_BOOSTER_25: boolean;
  CHATPOINT_BOOSTER_75: boolean;
  CHATPOINT_BOOSTER_175: boolean;
  CHATPOINT_BOOSTER_550: boolean;
  CHATPOINT_BOOSTER_1200: boolean;
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`/api/v1/chat-point-booster`, data);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Get Chat Point Booster Settings
export const getChatPointBooster = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/api/v1/chat-point-booster`);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
