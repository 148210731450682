import React from "react";
import { Link, useLocation } from "react-router-dom";

declare global {
  interface Window {
    fbq?: (...args: any[]) => void;
  }
}

interface SuccessfullPaymentProps {
  login: boolean;
}

interface SuccessfullPaymentState {}

class SuccessfullPayment extends React.Component<
  SuccessfullPaymentProps,
  SuccessfullPaymentState
> {
  state = {};

  componentDidMount() {
    if (window.fbq) {
      window.fbq("track", "Purchase", {
        value: 9.0, // Replace with actual transaction value if needed
        currency: "USD",
      });
    }
  }

  render() {
    return (
      <>
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 flex flex-col justify-between h-full">
                  <div
                    style={{ margin: "auto", width: "4rem", height: "4rem" }}
                    className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 m-auto "
                  >
                    <img src="/assets/images/successful-payment.svg" alt="" />
                  </div>

                  <div className="mt-auto">
                    <div className=" text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3
                        style={{ fontSize: "1.5rem", lineHeight: 1 }}
                        className="my-8 font-semibold  text-center"
                        id="modal-title"
                      >
                        Your payment was successfully processed.
                      </h3>
                    </div>
                  </div>
                  <Link
                    to={this.props.login ? "/successful-payment" : "/"}
                    style={{
                      background: "var(--primary-color)",
                      color: "#fff",
                      fontWeight: "600",
                      borderRadius: "1rem",
                    }}
                    className="py-4 w-full text-center"
                    onClick={() =>
                      this.props.login
                        ? (window.location.href = `${process.env.REACT_APP_REST_API_URI}/login`)
                        : ""
                    }
                  >
                    Go back home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const withRouter = (WrappedComponent: any) => (props: any) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const login = searchParams.get("login");

  console.log(login);

  return <WrappedComponent login={login} {...props} />;
};

export default withRouter(SuccessfullPayment);
