// import * as React from "react";
// import AudioPlayer from "react-h5-audio-player";
// import "react-h5-audio-player/lib/styles.css";
// import "./css/AudioPlayer.css";
// import Spin from "./spin";

// interface AudioPlayerProps {
//   audioUrl: string;
//   autoPlay?: boolean;
// }

// class AudioPlayerT extends React.Component<AudioPlayerProps> {
//   state = {
//     audioBlobUrl: "",
//   };

//   componentDidMount() {
//     this.loadAudio();
//   }

//   componentDidUpdate(prevProps: AudioPlayerProps) {
//     if (prevProps.audioUrl !== this.props.audioUrl) {
//       this.setState({ audioBlobUrl: "" });
//       this.loadAudio();
//     }
//   }

//   loadAudio = async () => {
//     const { audioUrl } = this.props;
//     try {
//       const response = await fetch(audioUrl, {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       });
//       if (response.ok) {
//         const blob = await response.blob();
//         const audioBlobUrl = URL.createObjectURL(blob);
//         this.setState({ audioBlobUrl });
//       } else {
//         console.error("Failed to fetch audio:", response.status);
//         this.setState({ audioBlobUrl: "" }); // Handle errors or set to a default audio file
//       }
//     } catch (error) {
//       console.error("Error fetching audio:", error);
//       this.setState({ audioBlobUrl: "" });
//     }
//   };

//   render() {
//     const { autoPlay } = this.props;
//     const { audioBlobUrl } = this.state;

//     if (audioBlobUrl.length === 0) {
//       return (
//         <>
//           <div
//             style={{ background: "#F0F0F0" }}
//             className=" flex flex-col justify-center items-center"
//           >
//             <Spin></Spin>
//             <p style={{ color: "var(--primary-color)" }}>Loading audio ...</p>
//           </div>
//         </>
//       );
//     }

//     return (
//       <>
//         <AudioPlayer
//           autoPlay={autoPlay}
//           src={audioBlobUrl}
//           onPlay={(e) => console.log("onPlay")}
//           // Additional props or listeners can be added here
//         />
//       </>
//     );
//   }
// }

import React from "react";

const AudioPlayerT = (props: any) => {
  console.log("Hello hello");
  const { audioUrl, autoPlay } = props;
  return (
    <audio src={audioUrl} autoPlay={autoPlay} controls>
      Your browser does not support the audio element.
    </audio>
  );
};

export default AudioPlayerT;
